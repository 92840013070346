/* ///login-page  start*/
.bg_photo {
	display: flex;
	background-repeat: no-repeat;
	background: url(../src/Images/LoginBg.png);
	width: 100%;
	height: 100vh
}

/* login-page end */

.input_shedow {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

p {
	margin-bottom: 0px;
}


main {
	/* padding: 10px; */
	overflow: hidden;
	width: 100%;
}

